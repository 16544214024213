import Vue from 'vue'

import VueRouter from 'vue-router'
// @ts-ignore
import store from '@/store/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dash',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Authentification/Register.vue'),
      meta: {
        pageTitle: 'Demande',
        breadcrumb: [
          {
            text: 'Inscription',
            active: true,
          },
        ],
        layout: 'full',
      },
    },
    {
      path: '/nos-etablissements',
      name: 'dashboard',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Home.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Demande',
        breadcrumb: [
          {
            text: 'Inscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/demande-inscription',
      name: 'demande',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Lead/Demande.vue'),
      meta: {
        requireLogin: true,
        pageTitle: 'Demande',
        breadcrumb: [
          {
            text: 'Inscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/lead',
      name: 'admin',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Admin/Lead.vue'),
      meta: {
        admin: true,
        requireLogin: true,
        pageTitle: 'Demande',
        breadcrumb: [
          {
            text: 'Inscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/lead/:id',
      name: 'lead-show',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Admin/Show.vue'),
      meta: {
        admin: true,
        requireLogin: true,
        pageTitle: 'Demande',
        breadcrumb: [
          {
            text: 'Inscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inscription',
      name: 'inscription',
      props: true,
      // @ts-ignore
      component: () => import('@/views/Authentification/Register.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Demande',
        breadcrumb: [
          {
            layout: 'horizontal',
            text: 'Inscription',
            active: true,
          },
        ],
      },
    },

    {
      path: '/login',
      name: 'auth',
      // @ts-ignore
      component: () => import('@/views/Authentification/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      // @ts-ignore
      component: () => import('@/views/Authentification/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      // @ts-ignore
      component: () => import('@/views/Authentification/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'auth-forgot-password',
      component: () => import('@/views/Authentification/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/password/reset/:uid/:token',
      name: 'auth-forgot-password-confirm',
      component: () => import('@/views/Authentification/ForgotPasswordConfirm.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      // @ts-ignore
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// eslint-disable-next-line radix

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && store.state.isAuthenticated === false) {
    next({ name: 'inscriptions' })
  // eslint-disable-next-line eqeqeq
  } else if (to.matched.some(record => record.meta.requireLogin) && to.matched.some(record => record.meta.admin) && store.state.role == false) {
    next({ name: 'error-404' })
  } else {
    next()
  }
})

export default router
